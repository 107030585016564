<template>
  <router-view v-slot="{ Component }">
    <transition
      name="fade-shortly"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss" src="./styles/app.scss"></style>
